import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import { Container, DangerouslySetInnerHtml, Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';

import './LdsPage.scss';

const LdsPage = ({
  pageContext,
  data: {
    ldsPage: { urls, seo },
  },
}: LdsTypes.LdsProps) => {
  const { title, keywords, description } = seo;

  const {
    lang,
    slug,
    body,
    ldsUrl,
    breadcrumb: { crumbs },
  } = pageContext;

  const crumbsSettings = {
    crumbs,
    isDisplayedCrumbs: true,
  };

  useEffect(() => {
    const heading = `<h1>${document.querySelector('p:first-child')?.innerHTML}</h1>`;

    document.querySelector('p:first-child')?.remove();
    document.querySelector('p:first-child')?.insertAdjacentHTML('beforebegin', heading);
  }, []);

  return (
    <Layout {...{ crumbsSettings }} headerTransparent>
      <Seo ldsUrl={ldsUrl} {...{ lang, urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <section className="lds-page" id="main-content">
        <Container fluid>
          {body ? <DangerouslySetInnerHtml html={body} className={slug} /> : null}
        </Container>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String, $ldsUrl: String) {
    ldsPage(lang: { eq: $lang }, url: { eq: $ldsUrl }) {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
    }
  }
`;

export default LdsPage;
